import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';
import 'viewerjs/dist/viewer.min.js';
import 'jquery-viewer/dist/jquery-viewer.min.js';
import 'select2/dist/js/select2.min.js';

// Uses as the variable for "Initiate Load More" script
// let loadMoreItems = 2;

// sticky header variable
var shrinkHeader = 5;

// Flickity.defaults.dragThreshold = 3;

Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // Testimonials Load More
    $(document).ready(function () {
      $('.testimonial-item-v1').slice(0, 5).show();
      $('.testimonial-item-v2').slice(0, 5).show();
      if ($('.testimonial-item-v1:hidden').length != 0 || $('.testimonial-item-v2:hidden').length != 0) {
        $('#loadMoreTestimonials').show();
      } else {
        $('#loadMoreTestimonials').hide();
      }
      $('#loadMoreTestimonials').on('click', function (e) {
        e.preventDefault();
        $('.testimonial-item-v1:hidden').slice(0, 2).slideDown();
        $('.testimonial-item-v2:hidden').slice(0, 2).slideDown();
        if ($('.testimonial-item-v1:hidden').length == 0) {
          $('#loadMoreTestimonials').text('No More to view').fadeOut('slow');
        }
      });
    });

    // Testimonials Mobile Load More
    $(document).ready(function () {
      $('.testimonial-item-mobile').slice(0, 10).show();
      if ($('.testimonial-item-mobile:hidden').length != 0) {
        $('#loadMoreTestimonialsMobile').show();
      } else {
        $('#loadMoreTestimonialsMobile').hide();
      }
      $('#loadMoreTestimonialsMobile').on('click', function (e) {
        e.preventDefault();
        $('.testimonial-item-mobile:hidden').slice(0, 5).slideDown();
        if ($('.testimonial-item-mobile:hidden').length == 0) {
          $('#loadMoreTestimonialsMobile').text('No More to view').fadeOut('slow');
        }
      });
    });

    // News Load More
    $(document).ready(function () {
      $('.news-item-wrp.left .news-item').slice(0, 3).show();
      $('.news-item-wrp.right .news-item').slice(0, 3).show();
      if ($('.news-item-wrp.left .news-item:hidden').length != 0 || $('.news-item-wrp.right .news-item:hidden').length != 0) {
        $('#loadMoreNews').show();
      } else {
        $('#loadMoreNews').hide();
      }
      $('#loadMoreNews').on('click', function (e) {
        e.preventDefault();
        $('.news-item-wrp.left .news-item:hidden').slice(0, 2).slideDown();
        $('.news-item-wrp.right .news-item:hidden').slice(0, 2).slideDown();
        if ($('.news-item-wrp.left .news-item:hidden').length == 0) {
          $('#loadMoreNews').text('No More to view').fadeOut('slow');
        }
      });
    });

    // News Mobile Load More
    $(document).ready(function () {
      $('.news-item-wrp.mobile .news-item').slice(0, 6).show();
      if ($('.news-item-wrp.mobile .news-item:hidden').length != 0) {
        $('#loadMoreNewsMobile').show();
      } else {
        $('#loadMoreNewsMobile').hide();
      }
      $('#loadMoreNewsMobile').on('click', function (e) {
        e.preventDefault();
        $('.news-item-wrp.mobile .news-item:hidden').slice(0, 3).slideDown();
        if ($('.news-item-wrp.mobile .news-item:hidden').length == 0) {
          $('#loadMoreNewsMobile').text('No More to view').fadeOut('slow');
        }
      });
    });

    // toggle class on hamburger icon click
    $('.mobile-burger-icon').click(function(){
      $('body, .mobile-burger-icon').toggleClass('show-menu');
    });

    // mobile sub menu navigation
    // level 1
    $('.mobile-menu .nav > li.menu-item-has-children').each(function(){
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">'+ $(this).find('> a').text() +'</div>');
      $(this).find('> a').click(function(e){
        e.preventDefault();
        console.log(this);
        $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
      });
    });

    // level 2
    $('.mobile-menu .nav > li.menu-item-has-children > .sub-menu-wrap li.menu-item-has-children').each(function(){
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">'+ $(this).find('> a').text() +'</div>');
      $(this).find('> a').click(function(e){
        e.preventDefault();
        console.log(this);
        $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
      });
    });

    // return link click
    $('.return-link').each(function(){
      $(this).click(function(){
         $(this).parent().parent('.sub-menu-wrap').removeClass('show-menu');
      });
    });

    // set header height to sticky overlay
    $('.sticky-overlay').outerHeight($('.header').outerHeight());

    formScripts();
    cardSectionSlider();
    gallerySectionSlider();
    jqueryViewer('#gallery-images-viewer-v2');
    jqueryViewer('#gallery-images-viewer-v1');
    fullWidthVideoPlayer();
    videoPopupPlayer();
    readMoreBtnV2();
    rippleButton();
    delayHomeVideo();
    btnVideoPopupPlayer();
    checkLastSection();
    checkPreFooterExistence();
    teamMemeberDescriptionExpand();
    formMultiSelect();
    logoSectionGridSlider();
    logoSectionSlider();
    TeamMemberModalClose();
    packagesSectionSlider();
    checkVideoGalleryPlugin();
    helloBarTrigger();
    // helloBarFixesonLoad();

    // flickity resize in initial load
    $('.cards-section.v2 .cards-inner-wrp .slider, .gallery-section.v1 .gallery-wrapper, .logo-section .logo-grid, .logo-section .logo-slider, .packages-section .section-inner-wrp .package-wrp').flickity('resize');

    packageTitleResize();

    // if (matchMedia('screen and (max-width: 992px)').matches ) {
    //   var helloBarTimeOut = '10000';
    // } else {
    //   helloBarTimeOut = '10000';
    // }

    // setTimeout(function() {
    //   helloBarFixes();
    // }, helloBarTimeOut);
  },
};

$(window).on('resize', function () {
  $('.cards-section.v2 .cards-inner-wrp .slider, .gallery-section.v1 .gallery-wrapper, .logo-section .logo-grid, .logo-section .logo-slider, .packages-section .section-inner-wrp .package-wrp').flickity('resize');

  $('.package-intro-content').css('height', 'auto');
  packageTitleResize();

  setTimeout(function() {
    packageTitleResize();
  }, 500);

  readMoreBtnV2();
  helloBarFixes();
});

$(window).on('resize', function () {
  
});

// sticky menu
$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}
// sticky menu

function formScripts() {
  $('.form-dropdown').select2({
    dropdownParent: $('.select2-wrapper'),
  });

  $('.button-wrp-outter').append($('.wpcf7-response-output'));
}

function cardSectionSlider() {
  var cellAlignValue = ''

  if (matchMedia('screen and (max-width: 767px)').matches ) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.cards-section.v2 .section-inner-wrp .cards-wrp .cards-inner-wrp .slider').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    adaptiveHeight: false,
    cellAlign: cellAlignValue,
  });

  $('.cards-section.v2').each(function() {
    $(this).find('.flickity-button-wrp').prepend($(this).find('.slider .flickity-button'))
  });
}

function gallerySectionSlider() {
  var cellAlignValue = ''

  if (matchMedia('screen and (max-width: 768px)').matches ) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.gallery-section.v1 .section-inner-wrp .gallery-wrapper').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    adaptiveHeight: true,
    cellAlign: cellAlignValue,
  });

  // $('.gallery-section.v1 .section-inner-wrp .gallery-wrapper .flickity-button').appendTo($('.gallery-section.v1 .section-inner-wrp .flickity-button-wrp'));

  $('.gallery-section.v1 ').each(function() {
    $(this).find('.flickity-button').appendTo($(this).find('.flickity-button-wrp'))
  });
}

// Implementation of Jquery Viewer
function jqueryViewer(ulId) {
  var $galleryImages = $(ulId);

  $galleryImages.viewer({
    tooltip: 0,
    inline: false,
    toolbar: {
      zoomIn: {
        show: 1,
        size: 'large',
      },
      zoomOut: {
        show: 1,
        size: 'large',
      },
      oneToOne: 0,
      reset: 0,
      prev: {
        show: 1,
        size: 'large',
      },
      play: {
        show: 0,
        size: 'large',
      },
      next: {
        show: 1,
        size: 'large',
      },
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0,
    },
    title: 0,
  });
}
// Implementation of Jquery Viewer

// Full Width Video Player
function fullWidthVideoPlayer() {
  for (let i = 1; i <= $('.playvideo').length ; i++) {
    let idName = 'play-video-' + i;
    let newIdName = 'video-iframe-' + i;
    $('.playvideo')[i-1].setAttribute('id', idName);
    $('.iframe-video')[i-1].setAttribute('id', newIdName);
    $('#'+idName).click(function(){
      // console.log(typeof('#'+idName));
      // $('#'+idName).siblings().addClass(newIdName);
      $('#'+ newIdName).css('opacity','1');
      $('#'+ newIdName)[0].src += '&autoplay=1';
      $('#' + idName).css({
        'opacity' : '0',
        'z-index' : '-1',
      });
    });
  }
}
// Full Width Video Player

// Popup Video Player for Two Column Section
function videoPopupPlayer() {
  var $videoSrc;
  $('.video-iframe-thumbnail').click(function() {
      $videoSrc = $(this).data('src');
  });

  $('#myModal').on('shown.bs.modal', function () {
  $('#video').attr('src',$videoSrc + '?autoplay=1&mute=1&amp;modestbranding=1&amp;showinfo=0');
  })

  $('#myModal').on('hide.bs.modal', function () {
    $('#video').attr('src','');
  })
}
// Popup Video Player for Two Column Section

function readMoreBtnV2() {
  $('<span class="btn-icon"></span>').appendTo('.btn.read-more-btn.v2');

  if (window.matchMedia('(max-width: 767px)').matches) {
    $('.btn.read-more-btn.v2').click(function() {
      $(this).find('.btn-icon').toggleClass('btn-hover');
      let textId = '#description-'+$(this).attr('id');
      $(textId).toggleClass('expanded-description');
      $(textId+' > .hidden-content').slideToggle();
      if ($(this).text()=='Read More') {
        $(this).text('Read Less');
        $('<span class="btn-icon"></span>').appendTo('.btn.read-more-btn.v2');
        $(this).find('.btn-icon').toggleClass('btn-hover');
      } else if($(this).text()=='Read Less') {
        $(this).text('Read More');
        $('<span class="btn-icon"></span>').appendTo('.btn.read-more-btn.v2');
      }
    });
  }
}

function rippleButton() {
  $('<div class="animation-wrp"><div class="animation-item"></div><div class="animation-item"></div><div class="animation-item"></div></div>').appendTo('.btn.read-more-btn.btn-video');
}

// Popup Video Player for Home
function btnVideoPopupPlayer() {
  var $videoSrc;
  $('.btn-video').click(function (e) {
      e.preventDefault();
      $videoSrc = $(this).data('src');
  });

  $('#popup-video-wrp').on('shown.bs.modal', function () {
  $('#video-popup').attr('src',$videoSrc + '?autoplay=1&mute=1&amp;modestbranding=1&amp;showinfo=0');
  })

  $('#popup-video-wrp').on('hide.bs.modal', function () {
    $('video-popup').attr('src','');
  })
}
// Popup Video Player for Home

// Delay Home Banner Video
function delayHomeVideo() {
  setTimeout(function() {
    $('#home-banner-iframe').attr('src',$('#hidden-iframe-url').text())
    $('#home-banner-video').attr('src',$('#hidden-video-file').text())
  }, 3000);
}
// Delay Home Banner Video

function checkLastSection() {
  if ($('section:last-child').hasClass('parallax-image-section')) {
    $('section:last-child').addClass('last-section');
    $('.pre-footer').addClass('parallax-above');
    $('body > .pre-footer').addClass('d-none');
  } else {
    $('footer > .pre-footer').addClass('d-none');
  }

  if ($('section:last-child').hasClass('bg-enabled')) {
    $('section:last-child').addClass('last-section');
    $('.pre-footer').addClass('bg-section-above');
  }
}

function checkPreFooterExistence() {
  if ($('.pre-footer').length == '0') {
    $('body').addClass('no-pre-footer');
  }
}

// Adds a class to description on readmore click
function teamMemeberDescriptionExpand() {

  // $( '.mobile-team-readmore-button' ).click(function() {
  //   let textId = '#description-'+$(this).attr('id');
  //   $(textId).toggleClass('expanded-description');
  //   $(textId+' > .hidden-content').slideToggle();
  // });
}
// Adds a class to description on readmore click

// Adds a class when multiple items were selected in form
function formMultiSelect() {
  $('.wpcf7-list-item-label').on('click', function () {
    if ($(this).parent().parent().siblings().length > 0) {
      $(this).parent().parent().toggleClass('selected');
    }
  });
}
// Adds a class when multiple items were selected in form

function logoSectionSlider() {
  if ($('.logo-section .section-inner-wrp .logo-slider').children().length > 6) {
    // Play with this value to change the speed
    let tickerSpeed = 1;

    let flickity = null;
    let isPaused = false;
    const slideshowEl = document.querySelector('.logo-section .section-inner-wrp .logo-slider');

    const update = () => {
      if (isPaused) return;
      if (flickity.slides) {
        flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
        flickity.selectedIndex = flickity.dragEndRestingSelect();
        flickity.updateSelectedSlide();
        flickity.settle(flickity.x);
      }
      window.requestAnimationFrame(update);
    };

    const pause = () => {
      isPaused = true;
    };

    const play = () => {
      if (isPaused) {
        isPaused = false;
        window.requestAnimationFrame(update);
      }
    };

    flickity = new Flickity(slideshowEl, {
      autoPlay: false,
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      selectedAttraction: 0.015,
      friction: 0.25,
    });

    flickity.x = 0;

    if (slideshowEl) {
      slideshowEl.addEventListener('mouseenter', pause, false);
      slideshowEl.addEventListener('focusin', pause, false);
      slideshowEl.addEventListener('mouseleave', play, false);
      slideshowEl.addEventListener('focusout', play, false);
    }

    flickity.on('dragStart', () => {
      isPaused = true;
    });

    update();
  }
}

function logoSectionGridSlider() {
  if ($('.logo-section .section-inner-wrp .logo-grid').children().length <= 6) {
    // Play with this value to change the speed
    let tickerSpeed = 1;

    let flickity = null;
    let isPaused = false;
    const slideshowEl = document.querySelector('.logo-section .section-inner-wrp .logo-grid');

    const update = () => {
      if (isPaused) return;
      if (flickity.slides) {
        flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
        flickity.selectedIndex = flickity.dragEndRestingSelect();
        flickity.updateSelectedSlide();
        flickity.settle(flickity.x);
      }
      window.requestAnimationFrame(update);
    };

    const pause = () => {
      isPaused = true;
    };

    const play = () => {
      if (isPaused) {
        isPaused = false;
        window.requestAnimationFrame(update);
      }
    };

    flickity = new Flickity(slideshowEl, {
      autoPlay: false,
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      watchCSS: true,
      selectedAttraction: 0.015,
      friction: 0.25,
    });

    flickity.x = 0;

    if (slideshowEl) {
      slideshowEl.addEventListener('mouseenter', pause, false);
      slideshowEl.addEventListener('focusin', pause, false);
      slideshowEl.addEventListener('mouseleave', play, false);
      slideshowEl.addEventListener('focusout', play, false);
    }

    flickity.on('dragStart', () => {
      isPaused = true;
    });

    update();
  }
}

function packagesSectionSlider() {
  $('.packages-section .section-inner-wrp .package-wrp').flickity({
    pageDots: false,
    prevNextButtons: false,
    wrapAround: false,
    adaptiveHeight: false,
    watchCSS: true,
    cellAlign: 'left',
  });
}

function TeamMemberModalClose() {
  $('.popup-close-btn').on('click', function() {
    $(this).parent().parent().modal('hide');
  });
}

function packageTitleResize() {
  var maxHeight = 0;

  $('.package-intro-content').css('height', 'auto');
  $('.packages-section').each(function() {
    $(this).find('.package-intro-content').each(function () {
      if ($(this).height() > maxHeight) {
        maxHeight = $(this).height();
      }
    });

    $(this).find('.package-intro-content').height(maxHeight);
  });
}

function checkVideoGalleryPlugin() {
  $('.one-column-section').each(function() {
    if ($(this).find('.content-wrp').children().hasClass('gallery-video-content')) {
      $(this).addClass('video-gallery-plugin');
    }
  });
}

function helloBarTrigger() {
  let hellorBarCheck = setInterval(function(){
    var helloBarHeight = $('.hb-bar-content').parent().siblings().attr('height');

    if (helloBarHeight > 0) {
      window.hellobar.trigger.event('sd-hello-bar');
      var helloBarHeightFinal = '';

      setTimeout(function() {
        helloBarHeightFinal = $('.hb-bar-content').parent().siblings().attr('height');

        $('.header, .main').css('padding-top', `${helloBarHeightFinal}px`);
        $('.mobile-menu > .menu-wrp').css('margin-top', `${helloBarHeightFinal}px`);
        $('.sticky-overlay').css('top', `${helloBarHeightFinal}px`);
      }, 500);

      clearInterval(hellorBarCheck);
    }
  }, 500);

  $('.hb-bar-content').parent().parent().find('button').click(function(){
    $('.header, .main').css('padding-top', '0');
    $('.mobile-menu > .menu-wrp').css('margin-top', '0');
    $('.sticky-overlay').css('top', '0');
  });
}

// function helloBarFixesonLoad() {
//   setTimeout(function() {
//     var observer = new MutationObserver(function(mutations) {
//       mutations.forEach(function() {
//         helloBarFixes();
//       });
//     });

//     var target = document.querySelector('.hb-bar-content').parentNode.parentNode;

//     observer.observe(target, { attributes : true, attributeFilter : ['style'] });
//   }, 2000);
// }

function helloBarFixes() {
  var helloBarParent = $('.hb-bar-content').parent().parent();
  var helloBarHeight = $('.hb-bar-content').parent().height();

  if (helloBarParent.css('display') == 'inline') {
    $('.header, .main').css('padding-top', helloBarHeight);
    $('.mobile-menu > .menu-wrp').css('margin-top', helloBarHeight);
    $('.sticky-overlay').css('top', helloBarHeight);
  }

  helloBarParent.find('button').click(function(){
    $('.header, .main').css('padding-top', '0');
    $('.mobile-menu > .menu-wrp').css('margin-top', '0');
    $('.sticky-overlay').css('top', '0');
  });
}
